<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$EntitiesName.Direction" :create="openCreateModal">
        <h1 class="title">Directions</h1>
      </page-header>

      <datatable
        ref="directionsList"
        :fetch="$Api.Direction.fetchDirections"
        :enableDownload="true"
        :entity="$EntitiesName.Direction"
      >
        <div slot="name" slot-scope="{ item }" title="Nom" :sortable="true">{{ item.name }}</div>
      </datatable>
    </div>

    <modal ref="createDirectionModal" @create="createDirection" title="Création d'une direction">
      <div class="columns">
        <div class="column is-half">    
          <ValidationObserver ref="form">
          <text-field label="Nom" data-test="sel-direction-name" v-model="direction.name" :inline="false" :edit="true" required/>
          </ValidationObserver>
        </div>
      </div>
    </modal>

  </div>

</template>

<script>
import axios from 'axios'
export default {
  name: 'directions',
  data() {
    return {
      direction :{},
    };
  },
    methods: {
    openCreateModal() {
      this.$refs.createDirectionModal.open();
    },
    createDirection() {
      // eslint-disable-next-line
      this.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }
        axios.post('/directions', this.direction).then((response) => {
          if (response.data.success) {
            this.direction = {};
            this.$refs.directionsList.refresh();
            this.$refs.createDirectionModal.close();
            this.$router.push({
              name: 'Direction',
              params: { id: response.data.id },
            });
          }
        });
      })
    },
  },

};
</script>

<style scoped>
.infos {
  display: inline-block;
}

.infos {
  width: 34%;
}
</style>